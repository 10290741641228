import {variables} from "../common-variables.js";

export const fixedHeader = () => {

    const header = variables.header;
    let scrollPos = 0;
    const func = () => {
        if(header){
            let windowTop = window.pageYOffset;
            let windowHeight = window.innerHeight / 3;
            let headerHeight = header.offsetHeight;

            if(header.classList.contains('fixed')){
                if(windowTop > scrollPos){
                    if(windowTop > headerHeight){
                        header.style.transition = 'top .3s';
                        header.style.top = '-200px';
                    } else {
                        header.style.transition = 'none';
                        header.style.top = `-${windowTop}px`;
                    }
                } else {
                    header.style.transition = 'top .3s';
                    header.style.top = '';
                }
                scrollPos = windowTop;
            }

            if(!header.classList.contains('bg-blue')){
                let opacity = windowTop/windowHeight;
                if(windowTop/windowHeight >= 0.7){
                    opacity = 0.7;
                }
                header.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
            }
        }
    }


    window.addEventListener('scroll', () => {
        func();
    });

}