import {variables} from "../common-variables.js";

export const responsiveMenu = () => {

    const func = () => {
        variables.navMenus.forEach(menu => {
            let windowHeight = window.innerHeight;
            let topHeight = menu.querySelector('.menu_top').offsetHeight;
            let center = menu.querySelector('.menu_center');
            let bottomHeight = menu.querySelector('.menu_bottom').offsetHeight;
            let height = windowHeight - (topHeight + bottomHeight);

            const items = menu.querySelectorAll('.menu_nav_items > li > a');
            let fontSize = 0;
            items.forEach(item => {
                item.style.fontSize = '';
            });
            if (items[0]) {
                fontSize = parseInt(window.getComputedStyle(items[0], null).getPropertyValue('font-size'));
            }
            for (let i = fontSize, n = 12; i >= n; i--) {
                let centerHeight = center.querySelector('.menu_nav').offsetHeight;
                items.forEach(item => {
                    item.style.fontSize = `${i}px`;
                });
                if (height > centerHeight) {
                    break;
                }
            }
        });
    }
    func();

    window.addEventListener('resize', () => {
        func();
    });

}