import {variables} from "../common-variables.js";
import {postPermalink} from "./post-permalink.js";

export const loadPosts = () => {

    $('#load-more-posts').each(function () {
        let btn = $(this);
        let wrapper = btn.closest('.posts').find('.posts_wrapper');
        let text = btn.text();
        let loadText = btn.attr('data-load');
        let pageID = btn.attr('data-page-id');
        let tagID = btn.attr('data-tag-id');
        let lastPage = Number(btn.attr('data-last-page'));
        let nextPage = 2;
        btn.on('click', function () {
            $.ajax({
                url: variables.ajaxUrl,
                type: "POST",
                data: {
                    'action': 'load_posts',
                    'pageID': pageID,
                    'tagID': tagID,
                    'paged': nextPage,
                },
                beforeSend: function () {
                    btn.addClass('rotate');
                    btn.text(loadText);
                },
                success: function (results) {
                    if (nextPage === lastPage) {
                        btn.closest('div').remove();
                    } else {
                        btn.removeClass('rotate');
                        btn.text(text);
                    }
                    nextPage++;
                    wrapper.append(results);
                    postPermalink();
                }
            });
        });
    });

}