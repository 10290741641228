export const postFaq = () => {

    $('.single-post_info_faq_question').on('click', function() {
       $(this).toggleClass('active');
       $(this).siblings('.single-post_info_faq_answer').each(function() {
           $(this).slideToggle(300);
       });
       $(this).closest('.single-post_info_faq').siblings('.single-post_info_faq').each(function() {
           $(this).find('.single-post_info_faq_question').removeClass('active');
           $(this).find('.single-post_info_faq_answer').slideUp(300);
       });
    });

}