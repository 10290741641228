export const type_text = () => {

    const typingTime = 60;
    const removalTime = 30;
    const delay = 2500;

    const typedText = document.querySelectorAll('.typed-text');
    typedText.forEach(text => {
        const stringsArray = [];
        const stringLength = [];
        const lines = text.querySelectorAll('.typed-text-lines span');
        const item = text.querySelector('.typed-text-item');
        lines.forEach(line => {
            stringsArray.push(line.textContent.replace(/\s+/g, ' ').trim());
            stringLength.push(line.textContent.replace(/\s+/g, ' ').trim().length);
        });
        stringsArray.forEach(string => {
            let max = Math.max.apply(null, stringLength);
            if(string.length === max){
                item.textContent = string;
                text.style.minHeight = `${text.offsetHeight}px`;
                item.textContent = '';
            }
        });

        if(stringsArray.length > 1){
            let i = 0;
            let j = 0;

            const func = () => {
                let typing = setInterval(() => {
                    if(i === stringsArray.length){
                        i = 0;
                    }
                    let symbolsArray = stringsArray[i].split('');
                    item.textContent += symbolsArray[j];
                    j++;
                    if(j === symbolsArray.length){
                        i++;
                        clearInterval(typing);
                        setTimeout(() => {
                            const removed = setInterval(() => {
                                item.textContent = item.textContent.slice(0, -1);
                                if(item.textContent === ''){
                                    j = 0;
                                    clearInterval(removed);
                                    f();
                                }
                            }, removalTime);
                        }, delay);
                    }
                }, typingTime);
            }

            func();

            const f = () => {
                func();
            }

        } else if(stringsArray.length === 1){
            let j = 0;

            let typing = setInterval(() => {
                let symbolsArray = stringsArray[0].split('');
                item.textContent += symbolsArray[j];
                j++;
                if(j === symbolsArray.length){
                    item.classList.remove('typed');
                    clearInterval(typing);
                }
            }, typingTime);
        }

    });
};
