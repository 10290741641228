import {cursor} from "./modules/cursor.js";
import {header} from "./modules/header/header.js";
import {services} from "./modules/services.js";
import {type_text} from "./modules/type_text.js";
import {tableOfContents} from "./modules/post/table-of-contents.js";
import {postFaq} from "./modules/post/post-faq.js";
import {postPermalink} from "./modules/post/post-permalink.js";
import {loadPosts} from "./modules/post/load-posts.js";
import {share} from "./modules/share.js";
import {aos} from "./modules/aos.js";
import {preloader} from "./modules/preloader.js";
import {fixedHeader} from "./modules/header/fixed-header.js";

document.addEventListener("DOMContentLoaded", function (event) {
    cursor();

    fixedHeader();
    header();
    type_text();
    services();
    tableOfContents();
    postFaq();
    postPermalink();
    loadPosts();
    share();
    aos();

    preloader();
});