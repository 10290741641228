export const postPermalink = () => {

    const links = document.querySelectorAll('.post-permalink');

    links.forEach(link => {
        link.addEventListener('mouseover', () => {
            let findLinks = link.closest('.post').querySelectorAll('.post-permalink');
            findLinks.forEach(findLink => {
                findLink.classList.add('active');
            });
        });
        link.addEventListener('mouseout', () => {
            let findLinks = link.closest('.post').querySelectorAll('.post-permalink');
            findLinks.forEach(findLink => {
                findLink.classList.remove('active');
            });
        });
    });

}