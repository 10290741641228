export const share = () => {

    const sharedButton = document.querySelectorAll('#share-project');

    sharedButton.forEach(btn => {
        if (navigator.share) {
            btn.addEventListener('click', () => {
                navigator.share({
                    title: btn.getAttribute('data-title'),
                    url: btn.getAttribute('data-url'),
                }).then(() => {
                    console.log('Thanks for sharing!');
                }).catch(console.error)
            });
        } else {
            btn.classList.add('pointer-events-none');
        }
    });

}