import {variables} from "./common-variables.js";

export const preloader = () => {

    const preloader = document.querySelector('#preloader');

    if(preloader){
        setTimeout(() => {
            variables.page.classList.remove('h-screen');
            preloader.classList.add('opacity-0');
        }, 200);
        setTimeout(() => {
            preloader.classList.add('invisible');
        }, 500);
    }

}