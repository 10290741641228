import {isMobile} from "../isMobile.js";
import {variables} from "../common-variables.js";
import {responsiveMenu} from "./responsive-menu.js";

export const header = () => {

    const burgers = document.querySelectorAll('.header_burger');

    burgers.forEach(burger => {
        if (isMobile.any() === null) {
            burger.addEventListener('mouseenter', () => {
                burger.classList.add('hover');
            });
            burger.addEventListener('mouseleave', () => {
                burger.classList.remove('hover');
            });
        }
        burger.addEventListener('click', () => {
            responsiveMenu();
            burger.classList.toggle('active');
            document.querySelector('body').classList.toggle('overflow-hidden');
            variables.navMenus.forEach(navMenu => {
                navMenu.classList.toggle('active');
            });
        });
    });

}