export const tableOfContents = () => {

    $('.table-of-contents_switcher').on('click', function() {
       $(this).toggleClass('active');
       $(this).siblings('.table-of-contents_links').each(function() {
          $(this).slideToggle(300);
       });
    });

    $('a').on('click', function (e) {
        if ($(this).attr('href').indexOf('#') >= 0) {
            let firstIndex = $(this).attr('href').indexOf('#');
            let lastIndex = $(this).attr('href').length;
            let id = $(this).attr('href').substring(firstIndex, lastIndex);
            if($(id).length > 0){
                e.preventDefault();
                let top = $(id).offset().top - 20;
                $('body,html').animate({scrollTop: top}, 800);
            }
        }
    });

    function windowHrefAnchor() {
        const href = window.location.href;
        if (href.indexOf('#') >= 0) {
            let firstIndex = href.indexOf('#');
            let lastIndex = href.length;
            let id = href.substring(firstIndex, lastIndex);
            if($(id).length > 0){
                let top = $(id).offset().top - 20;
                $('body,html').animate({scrollTop: top}, 1200);
            }
        }
    }
    windowHrefAnchor();

}