export const services = () => {
    $(".services_link").each(function () {
        let active_text = $(this).attr("data-active");
        let text = $(this).text();
        $(this).on("click", function (e) {
            e.preventDefault();
            $(this).toggleClass("active");
            $(this).closest('.services_item').find(".sub_services_container").slideToggle(300);

            if ($(this).hasClass("active")) {
                $(this).text(active_text);
            } else {
                $(this).text(text);
            }
        });
    });
};
